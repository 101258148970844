<template>
  <div class="loading">
    <van-loading type="spinner" color="#F8C10A" size="40px" text-color="#F8C10A">{{loadingText}}</van-loading>
  </div>
</template>

<script>
import { Loading } from 'vant';

export default {
  components:{vanLoading:Loading },
  props:{
    loadingText:{
      type:String,
      default:''
    }
    // showLoading:{
    //   type:Boolean,
    //   default:false,
    // }
  },
  data() {
		return {
			minusFlag:false,
			modalText:'',
			timer:null,
		};
  },
  watch: {
		loadingText(newValue){
			this.modalText = newValue
			if(newValue){
				let second = 2 * 60;
				this.timer = setInterval(() => {
					second--;
					if (second) {
						// console.log('=倒计时====',second)
						// toast.message = `倒计时 ${second} 秒`;
					} else {
						clearInterval(this.timer);
						 this.$emit("clear", true);
					}
				}, 1000);
			}else{
				if(this.timer) clearInterval(this.timer);
			}
		}
  },
}
</script>

<style lang="less" scoped>
@media screen and(max-width:768px) {
  .loading{
    width: 100%;
    /deep/.van-loading{
          top: 50%;
          left: 35% !important;
    }
  }
}
.loading{
    width: 100%;
    /deep/.van-loading{
          top: 50%;
          left: 45%;
    }
  }
.loading{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px ;
  top: 0px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}
</style>